import React from "react";
import { Link, graphql } from "gatsby";
import { rhythm } from "../utils/typography";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    // const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    const Header = styled.header`
      display: flex;
      flex-direction: row;
      margin-bottom: 1em;

      > h3 {
        margin-right: 0.5em;
      }
    
     a::before {
        content: "";
        width: 0;
        position: absolute;
        left: 0;
        bottom: -2px;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: var(--Black);
        transition: all 0.2s;
      }
      a:hover::before {
        width: 100%;
      }
    `;


    return (
      <Layout location={this.props.location} title="Awesome Articles and Reads">
        <Seo title="Awesome Articles" description="A list of the most awesome articles from blogs, websites, social media, etc. If I think it's awesome, it will be here." />
        <div>
          <ul>
            <li><a href="https://news.ycombinator.com/">Hacker News - blog</a></li>
            <li><a href="https://sive.rs/below-average">I assume I’m below average  - Derek Sivers blog</a></li>
            <li><a href="https://sive.rs/blog">Derek Sivers - blog</a></li>
            <li><a href="https://dev.to/">dev.to - blog</a></li>
            <li><a href="https://confs.tech/">Tech Conferences</a></li>
            <li><a href="https://nofreeplan.com/#intro">No Free Plan</a></li>
            <li><Link to="/blog/obsessed">Obsessed</Link></li>
          </ul>
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: {category: {eq: "blog"} } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            category
            published
          }
        }
      }
    }
  }
`;
